<script>
    import Button from "./Button.svelte";
    const credits = [
        ["Jordan O'Leary", "Game Design and Development"],
        ["theBlurryBox", "Art and Animation"],
        ["Valentin Cochet @coc_val", "Sound Effect Design"],
        ["Jake O'Connell", "UI Design and Video Production"],
        ["BananaMilk", "Music"],
        ["Lost Lumens / Brad Clark", "Audio Mastering and Playtesting"],
        ["Matt Sweda", "Brainstorming"],
        ["Kacey Misskelley", "User Experience Research"],
        ["Russell Champoux", "Playtesting"],
        ["Lochlan O'Leary", "Playtesting"],
        ["https://www.kenney.nl/", "Some Icons"],
    ];
</script>

<div class="flex">
    <aside class="flex flex-direction-column gap-15 pad-20">
        <div class="flex align-items-center gap-20">
            <Button
                on:click={() => window.goBack()}
                type="button"
                text="🠔 Back"
                class="sm"
            />
        </div>
    </aside>
    <main>
        <div class="pad-20">
            <h2>Credits</h2>
            <table>
                <tbody>
                    {#each credits as [name, did], i}
                        <tr><td>{name}</td><td>{did}</td></tr>
                    {/each}
                </tbody>
            </table>
            <p>
                And huge thanks to the many others unnamed above who were
                helpful to me during the process of creating this game!
            </p>
            <h2>Inspiration</h2>
            <p>
                Spellmasons drew inspiration from a number of incredible indie
                titles including: Nuclear Throne, Magicka, Into the Breach, and
                Hoplite
            </p>
        </div>
    </main>
</div>

<style>
    div {
        color: white;
    }
    /* Spacing between columns */
    td {
        padding-right: 15px;
    }
</style>

<script>
    import Button from "./Button.svelte";
    import { SAVE_PREFIX } from "./globals.js";
    let saveFiles = Object.keys(localStorage)
        // Get all localStorage that are save files
        .filter((x) => x.includes(SAVE_PREFIX))
        // Get the name that doesn't include the save prefix
        .map((x) => x.split(SAVE_PREFIX).join(""))
        // Have most recent at the top
        .reverse();
</script>

<div class="flex">
    <aside>
        <div class="flex flex-direction-column gap-15 pad-20">
            <div class="flex align-items-center gap-20">
                <Button
                    on:click={() => window.goBack()}
                    type="button"
                    text="🠔 Back"
                    class="sm"
                />
                <h1 class="options-title">
                    {globalThis.i18n("Previous Games")}
                </h1>
            </div>
        </div>
    </aside>

    <main>
        <div class="pad-20 flex flex-direction-column gap-15">
            {#each saveFiles as saveFile}
                <div>
                    <Button
                        on:click={() => window.load(saveFile)}
                        type="button"
                        text={saveFile}
                    />
                </div>
            {/each}
        </div>
    </main>
</div>

<style>
    aside {
        border-right: 2px solid #efefef;
        width: 300px;
        /* Ensure that the options aside will become scrollable if the screen is too small */
        overflow-y: auto;
        /* Ensure it doesn't overlap with the border around the edges of the window */
        max-height: 90vh;
    }

    .options-title {
        display: inline-block;
    }
</style>

<script>
    import { onMount } from "svelte";
    import { get } from "svelte/store";
    import Button from "./Button.svelte";
    import Lobby from "./Lobby.svelte";
    import {
        isInRoom,
        isConnecting,
        connected,
        syncConnectedWithPieState,
        disconnect,
    } from "./stores";
    let isJoining = false;
    let urlSearchParams = new URLSearchParams(location.search);
    let wsUrl = urlSearchParams.get("pieUrl");
    let gameName = urlSearchParams.get("game");
    let joinRoomErrorText = "";

    function connect() {
        if (wsUrl) {
            console.log("Menu: Connect to server", wsUrl);
            $isConnecting = true;
            const url = new URL(location.href);
            url.searchParams.set("pieUrl", wsUrl);
            window.history.pushState(null, null, url);
            return window
                .connect_to_wsPie_server(wsUrl)
                .catch(console.error)
                .then(syncConnectedWithPieState);
        }
        return Promise.reject("No wsUrl defined to connect to");
    }
    function leaveRoom() {
        window.pieLeaveRoom();
        $isInRoom = false;
    }
    function join() {
        if (gameName) {
            if (window.isConnected()) {
                // Note: Must not join a room until pixi is setup
                console.log("Menu: Connect to game name", gameName);
                // Now that we are both connected to the pieServer and assets are loaded,
                // we can host or join a game
                isJoining = true;
                // Reset error
                joinRoomErrorText = "";
                // TODO: differentiate making room and joining room
                window
                    .joinRoom({ name: gameName })
                    .then(() => {
                        const url = new URL(location.href);
                        url.searchParams.set("game", gameName);
                        window.history.pushState(null, null, url);
                        $isInRoom = true;
                    })
                    .catch((e) => {
                        console.error("Could not join room", e);
                        joinRoomErrorText = e;
                    })
                    .then(() => {
                        isJoining = false;
                    });
            } else {
                console.error(
                    "Cannot join room until pieClient is connected to a pieServer"
                );
            }
        } else {
            console.log("Cannot join game until a gameName is provided");
        }
    }
    onMount(() => {
        syncConnectedWithPieState();
    });
</script>

<div class="flex">
    <aside>
        <div class="flex flex-direction-column gap-15 pad-20">
            <div class="flex align-items-center gap-20">
                <Button
                    on:click={() => {
                        window.goBack();
                    }}
                    type="button"
                    text="🠔 Back"
                    class="sm"
                />
                <h1 class="options-title">{globalThis.i18n("Multiplayer")}</h1>
            </div>
        </div>
    </aside>

    <main>
        <div class="pad-20" style="min-width:400px;">
            {globalThis.i18n("Server Url")}
            <div>
                <input
                    bind:value={wsUrl}
                    on:keypress={(e) => {
                        e.key == "Enter" && connect();
                    }}
                />
                <div class="flex gap-15">
                    <Button
                        on:click={connect}
                        disabled={$connected}
                        text="Connect"
                    />
                    <Button
                        on:click={disconnect}
                        disabled={!$connected}
                        text="Disconnect"
                    />
                </div>
            </div>
            {#if $isConnecting}
                Connecting...
                <div class="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            {/if}
            {#if $connected}
                <p>{globalThis.i18n("Game name")}</p>
                <input
                    bind:value={gameName}
                    on:keypress={(e) => {
                        e.key == "Enter" && join();
                    }}
                />
                {#if isJoining}
                    Joining...
                    <div class="lds-ellipsis">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                {:else if $isInRoom}
                    <Button on:click={leaveRoom} text="Leave Room" />
                {:else}
                    <div class="flex gap-15">
                        <Button on:click={join} text="Host" />
                        <Button on:click={join} text="Join" />
                    </div>
                    <div style="color:red;">{joinRoomErrorText}</div>
                {/if}
            {/if}
            {#if $isInRoom}
                <Lobby />
            {/if}
        </div>
    </main>
</div>

<style>
    input {
        font-family: inherit;
        font-size: inherit;
        -webkit-padding: 0.4em 0;
        padding: 0.4em;
        margin: 0 0 0.5em 0;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 2px;
        width: 100%;
    }
    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ellipsis div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }
</style>

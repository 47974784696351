<script>
    export let isActive;
    let _class = "";
    export { _class as class };
</script>

<button
    class={`button-wrapper ${_class} ${isActive ? "is-active" : ""}`}
    on:click
    {...$$restProps}
>
    <div>
        <slot />
    </div>
</button>

<style lang="scss">
    button > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<script>
    import Button from "./Button.svelte";
    import Icon from "./IconSlot.svelte";
    export let setRoute;
    import { LOCALIZATION } from "./routes";
    import KeyMapping from "./KeyMapping.svelte";
    const CONTROLS = "KEY_MAPPING";
    const EXTRAS = "EXTRAS";
    import PlayerConfig from "./PlayerConfig.svelte";
    const PLAYER_CONFIG = "PLAYER_CONFIG";

    const AUDIO = "AUDIO";
    const VIDEO = "VIDEO";
    let optionsRoute = AUDIO;
    function setOptionsRoute(_optionsRoute) {
        optionsRoute = _optionsRoute;
    }
    function changeVolume(ev) {
        window.changeVolume(ev.target.value / 100);
    }
    function changeVolumeMusic(ev) {
        window.changeVolumeMusic(ev.target.value / 100);
    }
    function changeVolumeGame(ev) {
        window.changeVolumeGame(ev.target.value / 100);
    }
    let cinematicCameraEnabled = globalThis.cinematicCameraEnabled;
    function setCinematicCamera(ev) {
        globalThis.setCinematicCameraEnabled(ev.target.checked);
        cinematicCameraEnabled = globalThis.cinematicCameraEnabled;
    }
    let storedZoom = NaN;
    if (globalThis.STORAGE_ID_UI_ZOOM) {
        storedZoom = parseFloat(
            globalThis.storageGet(globalThis.STORAGE_ID_UI_ZOOM)
        );
    } else {
        console.error("globalThis.STORAGE_ID_UI_ZOOM is undefined");
    }
    let zoomAmount = isNaN(storedZoom) ? 1 : storedZoom;
</script>

<div class="flex">
    <aside>
        <div class="flex flex-direction-column gap-15 pad-20">
            <div class="flex align-items-center gap-20">
                <Button
                    on:click={() => window.goBack()}
                    type="button"
                    text="🠔 Back"
                    class="sm"
                />
                <h1 class="options-title">{globalThis.i18n("Settings")}</h1>
            </div>
            <div class="flex flex-direction-column gap-15">
                <Button
                    on:click={() => setOptionsRoute(AUDIO)}
                    isActive={optionsRoute == AUDIO}
                    type="button"
                    text="Audio"
                />
                <Button
                    on:click={() => setOptionsRoute(VIDEO)}
                    isActive={optionsRoute == VIDEO}
                    type="button"
                    text="Video"
                />
                <Button
                    on:click={() => setOptionsRoute(PLAYER_CONFIG)}
                    isActive={optionsRoute == PLAYER_CONFIG}
                    type="button"
                    text="Player Configuration"
                />
                <Button
                    on:click={() => setOptionsRoute(CONTROLS)}
                    isActive={optionsRoute == CONTROLS}
                    type="button"
                    text="Controls"
                />
                <Button
                    on:click={() => setOptionsRoute(EXTRAS)}
                    isActive={optionsRoute == EXTRAS}
                    type="button"
                    text="Extras"
                />
                {#if globalThis.skipTutorial && globalThis.isTutorialComplete !== undefined && !globalThis.isTutorialComplete()}
                    <Button
                        on:click={() => {
                            globalThis.skipTutorial();
                        }}
                        text="Skip Tutorial"
                    />
                {/if}
                <Button
                    on:click={() => globalThis.resetTutorial()}
                    type="button"
                    text="Reset Tutorial"
                />
                <Icon
                    on:click={() => {
                        setRoute(LOCALIZATION);
                    }}
                    class="button-wrapper-bg-2"
                >
                    <img
                        src="ui/localizationIcon.png"
                        alt="language options"
                        width="24"
                        height="24"
                        style="transform: scale(1.4);"
                    />
                </Icon>
            </div>
        </div>
    </aside>

    <main>
        <div class="pad-20">
            {#if optionsRoute == AUDIO}
                <div class="flex flex-direction-column gap-15">
                    {globalThis.i18n("Total Volume")}
                    <input
                        type="range"
                        value={window.volume * 100}
                        on:input={changeVolume}
                        min="0"
                        max="100"
                    />
                    {globalThis.i18n("Music Volume")}
                    <input
                        type="range"
                        value={window.volumeMusic * 100}
                        on:input={changeVolumeMusic}
                        min="0"
                        max="100"
                    />
                    {globalThis.i18n("Sound Effects Volume")}
                    <input
                        type="range"
                        value={window.volumeGame * 100}
                        on:input={changeVolumeGame}
                        min="0"
                        max="100"
                    />
                    <Button
                        on:click={() => window.playNextSong()}
                        text="Play next song"
                    />
                </div>
            {:else if optionsRoute == VIDEO}
                <div class="flex flex-direction-column gap-15">
                    <Button
                        on:click={() => {
                            if (globalThis.isElectron) {
                                globalThis.electronSettings.setFullscreen(true);
                            } else {
                                document.body
                                    .requestFullscreen()
                                    .catch((err) => {
                                        console.error(
                                            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
                                        );
                                    });
                            }
                        }}
                        text="Set Fullscreen"
                    />
                    <Button
                        on:click={() => {
                            if (globalThis.isElectron) {
                                globalThis.electronSettings.setFullscreen(
                                    false
                                );
                            } else {
                                document.exitFullscreen();
                            }
                        }}
                        text="Set Windowed Mode"
                    />
                    <label>
                        <input
                            type="checkbox"
                            bind:checked={cinematicCameraEnabled}
                            on:click={setCinematicCamera}
                        />
                        {globalThis.i18n("Enable Cinematic Camera")}
                    </label>
                    {#if globalThis.isElectron}
                        {globalThis.i18n("UI Scale")}: {zoomAmount}
                        <div class="flex gap-15">
                            <Button
                                on:click={() => {
                                    zoomAmount -= 0.1;
                                    // Round to 1 decimal point to protect against floating point errors
                                    zoomAmount =
                                        Math.round(zoomAmount * 10) / 10;
                                    globalThis.storageSet(
                                        globalThis.STORAGE_ID_UI_ZOOM,
                                        zoomAmount
                                    );
                                    globalThis.electronSettings.setUIZoom(
                                        zoomAmount
                                    );
                                }}
                                text="Smaller"
                            />
                            <Button
                                on:click={() => {
                                    zoomAmount += 0.1;
                                    // Round to 1 decimal point to protect against floating point errors
                                    zoomAmount =
                                        Math.round(zoomAmount * 10) / 10;
                                    globalThis.storageSet(
                                        globalThis.STORAGE_ID_UI_ZOOM,
                                        zoomAmount
                                    );
                                    globalThis.electronSettings.setUIZoom(
                                        zoomAmount
                                    );
                                }}
                                text="Bigger"
                            />
                        </div>
                    {/if}
                </div>
            {:else if optionsRoute == PLAYER_CONFIG}
                <PlayerConfig />
            {:else if optionsRoute == CONTROLS}
                <KeyMapping />
            {:else if optionsRoute == EXTRAS}
                <div>
                    <Button
                        on:click={() => {
                            window.monitorFPS();
                        }}
                        text="Toggle FPS / Latency Monitor"
                    />
                </div>
            {/if}
        </div>
    </main>
</div>

<style>
    aside {
        border-right: 2px solid #efefef;
        width: 300px;
        /* Ensure that the options aside will become scrollable if the screen is too small */
        overflow-y: auto;
        /* Ensure it doesn't overlap with the border around the edges of the window */
        max-height: 90vh;
    }

    .options-title {
        display: inline-block;
    }
</style>

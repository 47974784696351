<script>
    import Button from "./Button.svelte";
    import Icon from "./IconSlot.svelte";
    import IconLink from "./IconLink.svelte";
    import {
        HELP,
        CREDITS,
        OPTIONS,
        MULTIPLAYER_SERVER_CHOOSER,
        LOCALIZATION,
        LOAD_GAMES,
    } from "./routes";
    import {
        syncConnectedWithPieState,
        disconnect,
        connected,
        isInRoom,
    } from "./stores";
    import { SAVE_PREFIX } from "./globals.js";
    export let setRoute;
    export let inGame;
    if (globalThis.STORAGE_ID_UI_ZOOM) {
        // Restore ui zoom from storage
        const storedZoom = parseFloat(
            globalThis.storageGet(globalThis.STORAGE_ID_UI_ZOOM)
        );
        if (storedZoom) {
            globalThis.electronSettings.setUIZoom(storedZoom);
        }
    } else {
        console.error("globalThis.STORAGE_ID_UI_ZOOM is undefined");
    }
    function isConnectedToSinglePlayer() {
        // 'solomode_client_id' comes from pieclient's solo mode
        return globalThis.clientId == "solomode_client_id";
    }
    function chooseSingleplayer() {
        // Start music on button click to get past Firefox permissions
        window.playMusic();
        // Connect to solo
        window.startSingleplayer().then(() => {
            syncConnectedWithPieState();
        });
    }
    function chooseMultiplayer() {
        // Start music on button click to get past Firefox permissions
        window.playMusic();
        if (isConnectedToSinglePlayer()) {
            disconnect();
        }
        setRoute(MULTIPLAYER_SERVER_CHOOSER);
    }

    function exitGame() {
        const doQuit = confirm("Are you sure you want to quit to Main Menu?");
        if (doQuit) {
            // Clear game and pieUrl searchParams on quit
            const url = new URL(location.href);
            url.searchParams.delete("game");
            url.searchParams.delete("pieUrl");
            window.history.pushState(null, null, url);
            window.exitCurrentGame().then(syncConnectedWithPieState);
        }
    }
    function resumeGame() {
        window.closeMenu();
    }
    function doSave() {
        const saveName = prompt(i18n("Enter a name for the saved game"));
        if (saveName) {
            if (localStorage.getItem(SAVE_PREFIX + saveName)) {
                const doOverwrite = confirm(
                    i18n(
                        "A save file with this name already exists.  Are you sure you want to overwrite it?"
                    )
                );
                if (!doOverwrite) {
                    return;
                }
            }
            window.save(saveName);
            alert("Game saved!");
        }
    }
    const quicksave_exists = !!globalThis.storageGet(
        `${globalThis.savePrefix}${globalThis.quicksaveKey}`
    );
    // function saveGame() {
    //     const fileName = prompt("Name your save file");
    //     console.log("saving...", fileName);
    //     window.save(fileName);
    // }
    // Since globalThis.isTutorialComplete is outside of the svelte
    // context, we have to forceRerender the tutorialSkip button after
    // it is pressed so it will recheck isTutorialComplete to decide
    // if it should render or not.
    let forceRerenderTutorialSkip = 0;
</script>

<div id="corner-left" class="flex gap" style="flex-direction:column;">
    <Button
        on:click={() => setRoute(HELP)}
        text="Help"
        iconPath="question.png"
    />
</div>
<div id="corner" class="flex gap" style="flex-direction:column;">
    <Button on:click={() => setRoute(CREDITS)} text="Credits" />
    <div class="flex gap">
        <IconLink
            src="ui/UI_Bird.png"
            alt="Twitter @spellmasons"
            width="24px"
            href="https://twitter.com/spellmasons"
            class="button-wrapper-bg-2"
        />
        <IconLink
            src="ui/discord_logo.png"
            alt="Spellmasons Discord https://discord.gg/q6sUCreHeJ"
            width="24px"
            href="https://discord.gg/q6sUCreHeJ"
            class="button-wrapper-bg-2"
        />
        <Icon
            on:click={() => {
                setRoute(LOCALIZATION);
            }}
            class="button-wrapper-bg-2"
        >
            <img
                src="ui/localizationIcon.png"
                alt="language options"
                width="24"
                height="24"
                style="transform: scale(1.4);"
            />
        </Icon>
        <!-- <Icon src="ui/UI_Bug.png" alt="Report Issue or Bug" /> -->
    </div>
</div>

{#if inGame}
    <div class="list">
        {#if $connected && $isInRoom && !globalThis.player.lobbyReady}
            <!--
                Handle special case where if the player has joined a game
                but is still in the lobby because they haven't readied up yet, resumeGame
                will do nothing because they cannot close the menu yet, so there must be a button
                to bring them back to the lobby
            -->
            <Button
                on:click={() => {
                    setRoute(MULTIPLAYER_SERVER_CHOOSER);
                }}
                text="Back to Lobby"
            />
        {:else}
            <Button
                on:click={resumeGame}
                text="Resume Game"
                iconPath="forward.png"
            />
            <!-- Only show save/load buttons in singleplayer until I implement support for saving multiplayer games -->
            {#if isConnectedToSinglePlayer()}
                <Button on:click={doSave} text="Save" iconPath="save.png" />
            {/if}
        {/if}
        {#key forceRerenderTutorialSkip}
            {#if globalThis.skipTutorial && globalThis.isTutorialComplete !== undefined && !globalThis.isTutorialComplete()}
                <Button
                    on:click={() => {
                        globalThis.skipTutorial();
                        forceRerenderTutorialSkip++;
                    }}
                    text="Skip Tutorial"
                />
            {/if}
        {/key}
        <!-- Only show save/load buttons in singleplayer until I implement support for saving multiplayer games -->
        {#if isConnectedToSinglePlayer()}
            <Button
                on:click={() => setRoute(LOAD_GAMES)}
                text="Load"
                iconPath="open.png"
            />
        {/if}
        <Button
            on:click={() => setRoute(OPTIONS)}
            text="Settings"
            iconPath="gear.png"
        />
        <Button
            on:click={exitGame}
            text="Quit to Main Menu"
            iconPath="exitLeft.png"
        />
    </div>
{:else}
    {#await window.setupPixiPromise}
        loading assets...
    {:then}
        <div class="list">
            {#if quicksave_exists}
                <Button
                    on:click={() => {
                        globalThis.load(globalThis.quicksaveKey);
                    }}
                    text="Resume Last Run"
                    iconPath="forward.png"
                />
            {/if}
            <Button
                on:click={chooseSingleplayer}
                text="New Run"
                iconPath="singleplayer.png"
            />
            <Button
                on:click={chooseMultiplayer}
                text="Multiplayer"
                iconPath="massiveMultiplayer.png"
            />
            <Button
                on:click={() => setRoute(LOAD_GAMES)}
                text="Load"
                iconPath="open.png"
            />
            <Button
                on:click={() => setRoute(OPTIONS)}
                text="Settings"
                iconPath="gear.png"
            />
            {#if globalThis.isElectron}
                <Button
                    on:click={() => {
                        window.fullyExitGame();
                    }}
                    text="Quit Game"
                    iconPath="door.png"
                />
            {/if}
        </div>
        <br />
    {:catch}
        <p style="color: red">Something went wrong loading assets</p>
    {/await}
{/if}

<style>
    #corner {
        position: absolute;
        bottom: 22px;
        right: 24px;
    }
    #corner-left {
        position: absolute;
        bottom: 22px;
        left: 24px;
    }
    .gap {
        gap: 15px;
    }
    .list {
        display: flex;
        flex-direction: column;
        max-width: 200px;
        margin: 0 auto;
    }
</style>

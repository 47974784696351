import { writable } from 'svelte/store';

export const lobbyPlayerList = writable([]);
export const connected = writable(false);
export const isInRoom = writable(false);
export const isConnecting = writable(false);
export function syncConnectedWithPieState() {
    const _connected = window.isConnected()
    connected.set(_connected);
    if (!_connected) {
        isInRoom.set(false);
    }
    isConnecting.set(false);
}
export function disconnect() {
    return window.pieDisconnect().then(syncConnectedWithPieState);
}
<script>
    import { onMount } from "svelte";
    import { lobbyPlayerList } from "./stores";
    // storage ids from config.ts in Golems
    let name = globalThis.storageGet("player-name");
    let color = `#${parseInt(globalThis.storageGet("player-color")).toString(
        16
    )}`;
    let colorMagic = `#${parseInt(
        globalThis.storageGet("player-color")
    ).toString(16)}`;
    const convertToHex = (cssColor) => parseInt(cssColor.slice(1), 16);
    function sendConfig(color, colorMagic, name) {
        window.configPlayer({
            color: convertToHex(color),
            colorMagic: convertToHex(colorMagic),
            name,
        });
        // Update lobbyPlayerList
        $lobbyPlayerList = globalThis.lobbyPlayerList;
    }
    onMount(() => {
        // Temporary work around, since all self messages are handled immediately,
        // the config is being overwritten when underworld sends SYNC_PLAYERS due to
        // onClientConnected even tho this messages is processed after so I'm adding
        // a delay; however, the real solution is to update wsPie to add a flag that
        // says sendToSelfThroughServer so it doesn't handle it immediately
        setTimeout(() => {
            sendConfig(color, colorMagic, name);
        }, 200);
    });
</script>

<div class="flex gap-15">
    <div class="flex flex-direction-column gap-15">
        <label for="player-name">{globalThis.i18n("Player Name")}</label>
        <input
            type="text"
            name="player-name"
            bind:value={name}
            on:input={() => {
                sendConfig(color, colorMagic, name);
            }}
        />
    </div>
    <div class="flex flex-direction-column gap-15">
        <label for="robe">{globalThis.i18n("Robe Color")}</label>
        <input
            type="color"
            name="robe"
            bind:value={color}
            on:blur={() => {
                sendConfig(color, colorMagic, name);
            }}
        />
    </div>
    <!-- <div class="flex flex-direction-column gap-15">
        <label for="magic color">{globalThis.i18n("Magic Color")}</label>
        <input
            type="color"
            name="magic color"
            bind:value={colorMagic}
            on:blur={() => {
                sendConfig(color, colorMagic, name);
            }}
        />
    </div> -->
</div>

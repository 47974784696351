<script>
    import Button from "./Button.svelte";
</script>

<h1>This page is still being built</h1>

<Button
    on:click={() => window.goBack()}
    type="button"
    text="🠔 Back"
    class="sm"
/>

<style>
    h1 {
        color: white;
    }
</style>

<script>
    import { onMount, onDestroy } from "svelte";
    import Button from "./Button.svelte";
    import PlayerConfig from "./PlayerConfig.svelte";
    import { lobbyPlayerList } from "./stores";
    let intervalId;
    onMount(() => {
        setInterval(() => {
            // Update lobbyPlayerList
            $lobbyPlayerList = globalThis.lobbyPlayerList;
        }, 500);
    });
    onDestroy(() => {
        clearInterval(intervalId);
    });
</script>

<h1>{globalThis.i18n("Game Lobby")}</h1>
<PlayerConfig />
<Button
    on:click={() => {
        globalThis.configPlayer({ lobbyReady: true });
    }}
    type="button"
    text="Ready"
    class="sm"
/>
<table id="lobby-player-list">
    <thead>
        <tr />
    </thead>
    <tbody>
        {#each $lobbyPlayerList as { name, status, color, ready }}
            <tr>
                <td>{name}</td>
                <td>{status}</td>
                <td
                    class="lobby-player-color"
                    style={`background-color:${color};`}
                />
                <td>
                    {ready}
                </td>
            </tr>
        {/each}
    </tbody>
</table>

<style>
    #lobby-player-list {
        min-width: 500px;
    }
    .lobby-player-color {
        width: 20px;
        height: 18px;
    }
</style>

<script>
    import { onMount, onDestroy } from "svelte";
    function changeControl(key, index, code) {
        globalThis.controlMap[activeKey][activeIndex] = code;
        globalThis.persistControls();
        forceRerender++;
        // Clear active
        setActive("", 0);
    }
    function keydownListener(event) {
        if (activeKey) {
            if (globalThis.controlMap) {
                changeControl(activeKey, activeIndex, event.code);
            } else {
                console.error("Cannot set key, no globalThis.controlMap");
            }
        }
    }
    function mousedownListener(event) {
        if (!globalThis.mouseButtonToKeyCode) {
            console.error("mouseButtonToKeyCode is undefined");
        }
        if (event.button !== undefined) {
            const mouseButton = globalThis.mouseButtonToKeyCode(event.button);

            if (activeKey) {
                if (globalThis.controlMap) {
                    if (
                        event.button == 0 ||
                        event.button == 1 ||
                        event.button == 2
                    ) {
                        error = globalThis.i18n("mouseRebindError");
                    } else {
                        changeControl(activeKey, activeIndex, mouseButton);
                    }
                } else {
                    console.error("Cannot set key, no globalThis.controlMap");
                }
            }
        }
    }
    onMount(() => {
        document.body.addEventListener("keydown", keydownListener);
        document.body.addEventListener("mousedown", mousedownListener);
    });

    onDestroy(() => {
        document.body.removeEventListener("keydown", keydownListener);
        document.body.removeEventListener("mousedown", mousedownListener);
    });
    let activeKey = "";
    let activeIndex = 0;
    // forceRerender is needed because this component edits a global variable which svelte stay in
    // sync with like other variables
    let forceRerender = 0;
    function setActive(key, index) {
        activeKey = key;
        activeIndex = index;
        // Reset error
        error = "";
    }
    let error = "";
</script>

<h2 style="color:red;">&nbsp;{error}</h2>
<table id="keymapping">
    {#key forceRerender}
        <tr>
            <td />
            <td>Option 1</td>
            <td>Option 2</td>
            <td>Option 3</td>
        </tr>
        {#each Object.entries(globalThis.controlMap || {}) as [key, controls]}
            <tr>
                <td>
                    {globalThis.i18n(key)}
                </td>
                {#each Object.assign(["", "", ""], controls) as control, index}
                    <td
                        ><button
                            class="reassignKey"
                            on:click={() => setActive(key, index)}
                        >
                            {#if activeKey == key && activeIndex == index}
                                {globalThis.i18n("reassign")}
                            {:else}
                                {(control || "")
                                    .split("Key")
                                    .join("")
                                    .split("Digit")
                                    .join("")
                                    .toLowerCase()}
                            {/if}
                        </button></td
                    >
                {/each}
            </tr>
        {/each}
    {/key}
</table>

<style>
    .reassignKey {
        min-width: 200px;
        min-height: 24px;
    }
</style>

<script>
    export let src;
    export let alt;
    export let href;
    export let width;
    export let isActive;
    export let text = "";
    let _class = "";
    export { _class as class };
</script>

<a target="_blank" rel="noreferrer" {href}>
    <button
        class={`button-wrapper ${_class} ${isActive ? "is-active" : ""}`}
        on:click
        {...$$restProps}
    >
        <div>
            <img {src} {width} {alt} />
            <span>{text}</span>
        </div>
    </button>
</a>

<style lang="scss">
    button > div {
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

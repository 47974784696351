<script>
    import Button from "./Button.svelte";
    export const languages = globalThis.getSupportedLanguages();
    let currentLanguageCode = globalThis.getChosenLanguageCode();
</script>

<div class="flex">
    <aside>
        <div class="flex flex-direction-column gap-15 pad-20">
            <div class="flex align-items-center gap-20">
                <Button
                    on:click={() => window.goBack()}
                    type="button"
                    text="🠔 Back"
                    class="sm"
                />
                <h1 class="options-title">{globalThis.i18n("Localization")}</h1>
            </div>
        </div>
    </aside>

    <main>
        <div class="pad-20 flex flex-direction-column gap-15">
            {#each languages as { language, code }, i}
                <Button
                    text={language}
                    isActive={currentLanguageCode == code}
                    doLocalizeText={false}
                    on:click={() => {
                        globalThis.setLanguage(code, true);
                        currentLanguageCode = code;
                        window.goBack();
                    }}
                />
            {/each}
        </div>
    </main>
</div>

<style>
    aside {
        border-right: 2px solid #efefef;
        width: 300px;
    }
    .options-title {
        display: inline-block;
    }
</style>

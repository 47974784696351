<script>
    import Button from "./Button.svelte";
    import IconLink from "./IconLink.svelte";
</script>

<div class="flex height100">
    <aside>
        <div class="flex flex-direction-column gap-15 pad-20">
            <div class="flex align-items-center gap-20">
                <Button
                    on:click={() => window.goBack()}
                    type="button"
                    text="🠔 Back"
                    class="sm"
                />
                <h1 class="options-title">{globalThis.i18n("Help")}</h1>
            </div>
            <div class="flex flex-direction-column gap-15">
                <!-- <Button
                    on:click={() => setOptionsRoute(AUDIO)}
                    isActive={optionsRoute == AUDIO}
                    type="button"
                    text="Audio"
                />
                <Button
                    on:click={() => setOptionsRoute(PLAYER_CONFIG)}
                    isActive={optionsRoute == PLAYER_CONFIG}
                    type="button"
                    text="Player Configuration"
                />
                <Button
                    on:click={() => setOptionsRoute(CONTROLS)}
                    isActive={optionsRoute == CONTROLS}
                    type="button"
                    text="Controls"
                />
                <Button
                    on:click={() => setOptionsRoute(EXTRAS)}
                    isActive={optionsRoute == EXTRAS}
                    type="button"
                    text="Extras"
                /> -->
            </div>
        </div>
    </aside>

    <main>
        <div class="flex gap-15 height100">
            <div
                class="pad-20 flex flex-direction-column gap-15 overflowyscroll"
                style="min-width:300px"
            >
                <h1>Contact Me!</h1>
                <IconLink
                    src="ui/UI_Bird.png"
                    alt="Twitter @spellmasons"
                    width="24px"
                    href="https://twitter.com/spellmasons"
                    class="button-wrapper-bg-2"
                    text="@spellmasons"
                />
                <h1>Join our Discord Community!</h1>
                <IconLink
                    src="ui/discord_logo.png"
                    alt="Spellmasons Discord https://discord.gg/q6sUCreHeJ"
                    width="24px"
                    href="https://discord.gg/q6sUCreHeJ"
                    class="button-wrapper-bg-2"
                    text="Discord"
                />
                <h1>How to</h1>
                {#each window.explainKeys || [] as name}
                    <Button
                        on:click={() => window.menuExplain(name, true)}
                        type="button"
                        text={name}
                    />
                {/each}
                <!-- {#if optionsRoute == AUDIO}
                <div class="flex flex-direction-column gap-15">
                    Total Volume:
                    <input
                        type="range"
                        value={window.volume * 100}
                        on:input={changeVolume}
                        min="0"
                        max="100"
                    />
                    Music Volume:
                    <input
                        type="range"
                        value={window.volumeMusic * 100}
                        on:input={changeVolumeMusic}
                        min="0"
                        max="100"
                    />
                    Sound Effects Volume:
                    <input
                        type="range"
                        value={window.volumeGame * 100}
                        on:input={changeVolumeGame}
                        min="0"
                        max="100"
                    />
                </div>
            {:else if optionsRoute == PLAYER_CONFIG}
                <PlayerConfig  />
            {:else if optionsRoute == CONTROLS}
                <KeyMapping />
            {:else if optionsRoute == EXTRAS}
                <div>
                    <Button
                        on:click={() => {
                            window.monitorFPS();
                        }}
                        text="Toggle FPS/Latency Monitor"
                    />
                </div>
            {/if} -->
            </div>
            <div id="explain-portal" />
        </div>
    </main>
</div>

<style>
    aside {
        border-right: 2px solid #efefef;
        width: 300px;
    }
    .options-title {
        display: inline-block;
    }
    #explain-portal {
        width: 100%;
        height: 100vh;
        display: flex;
        align-content: center;
    }
</style>

<script>
  console.log("Menu: Svelte menu is running");
  import { onMount, onDestroy } from "svelte";
  import Credits from "./Credits.svelte";
  import Load from "./Load.svelte";
  import Help from "./Help.svelte";
  import Options from "./Options.svelte";
  import Pie from "./Pie.svelte";
  import Localization from "./Localization.svelte";
  import PageInProgress from "./PageInProgress.svelte";
  import {
    PLAY,
    OPTIONS,
    CREDITS,
    HELP,
    TODO,
    LOCALIZATION,
    MULTIPLAYER_SERVER_CHOOSER,
    LOAD_GAMES,
  } from "./routes";
  import Multiplayer from "./Multiplayer.svelte";
  let route;
  let inGame = false;
  let forceRerender = 0;
  // Used when changing the language to ensure the menu
  // updates to the new language
  window.refreshMenu = () => {
    console.log("Force refresh menu");
    forceRerender++;
  };
  window.updateInGameMenuStatus = () => {
    inGame = window.player !== undefined;
  };
  setRoute(PLAY);
  function setRoute(r) {
    console.log("Menu: Route:", r);
    route = r;
    window.updateInGameMenuStatus();
  }
  window.goBack = function goBack() {
    setRoute(PLAY);
  };
  function keydownListener(event) {
    switch (event.code) {
      case "Escape":
        goBack();
        break;
    }
  }

  let updateProgress = 0;
  // Start with updateComplete set to true so that the update UI will
  // ONLY EVER hide the play ui if update messages start coming through
  let updateComplete = true;
  onMount(() => {
    document.body.addEventListener("keydown", keydownListener);

    if (globalThis.isElectron) {
      if (globalThis.update) {
        console.log("Register to receive Update events.");
        globalThis.update.onUpdateCounter((_sender, updateState) => {
          console.log("Electron app update:", updateState);
          if (updateState.progress !== undefined) {
            updateProgress = updateState.progress;
          }
          if (updateState.complete !== undefined) {
            updateComplete = updateState.complete;
          }
        });
      } else {
        console.error(
          "Update: Cannot track update state, no globalThis.update"
        );
      }
    }
  });

  onDestroy(() => {
    document.body.removeEventListener("keydown", keydownListener);
  });
  window.setMenu = setRoute;
</script>

{#key forceRerender}
  <div id="menu">
    <div class="full-fill">
      <div class="decorative-border">
        {#if route == PLAY}
          <div id="main-menu">
            <div id="main-menu-inner">
              <img
                id="logo"
                src="ui/logo.png"
                alt="Spellmasons logo"
                width="800"
              />
              {#if updateComplete}
                <Pie {setRoute} {inGame} />
              {:else}
                <span>Update progress:</span>
                <div class="progress">
                  <div
                    style={`width:${Math.max(
                      // Always give enough room to show the percentage text
                      15,
                      Math.floor(updateProgress * 100)
                    )}%`}
                  >
                    {Math.floor(updateProgress * 100)}% &nbsp;
                  </div>
                </div>
              {/if}
            </div>
          </div>
        {:else if route == MULTIPLAYER_SERVER_CHOOSER}
          <Multiplayer route />
        {:else if route == LOCALIZATION}
          <Localization route />
        {:else if route == LOAD_GAMES}
          <Load route {setRoute} />
        {:else if route == OPTIONS}
          <Options route {setRoute} />
        {:else if route == CREDITS}
          <Credits route />
        {:else if route == HELP}
          <Help route />
        {:else if route == TODO}
          <PageInProgress route />
        {/if}
      </div>
    </div>
  </div>
{/key}

<style global lang="scss">
  body {
    margin: 0;
  }
  #logo {
    width: 100%;
    margin: 0 auto;
    max-width: 800px;
  }
  #menu {
    color: white;
    font-family: "Playfair Display", serif;
  }
  #main-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #171717;
  }
  #main-menu-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .progress {
    height: 32px;
    width: 250px;
    appearance: none;
    border-radius: 8px;
    margin: 8px;
    border: 1px solid #320573;
  }
  .progress > div {
    background-image: -webkit-linear-gradient(left, #320573, #9717e3);
    border-radius: 8px;
    text-align: right;
    line-height: 32px;
    height: 32px;
  }
  #main-menu input {
    font-family: inherit;
    font-size: inherit;
    -webkit-padding: 0.4em 0;
    padding: 0.4em;
    margin: 0 0 0.5em 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 2px;
    width: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 250px;
    font-family: sans-serif;
  }
  // ------------------------
  .height100 {
    height: 100%;
  }
  .overflowyscroll {
    overflow-y: scroll;
  }
  .flex {
    display: flex;
  }
  .flex-direction-column {
    flex-direction: column;
  }
  .align-items-center {
    align-items: center;
  }
  .gap-20 {
    gap: 20px;
  }
  .gap-15 {
    gap: 15px;
  }
  $button-purple: #483f8e;
  // Remove default button styling
  .button-wrapper {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: white;
    text-align: center;
    width: 100%;
    max-width: 300px;
  }
  .button-wrapper.sm {
    max-width: 120px;
  }
  .button-wrapper {
    border: 16px solid;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 250;
    border-image-source: url("/ui/Assets_Icon_Border_QtrCircle_2_Purple.png");
    border-image-width: 1;
    transition: 0.2s;
    // Allow button to scale without blurring. Credit: https://stackoverflow.com/a/16878602/4418836
    backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
    /* background-clip ensures that the background color wont overlap the border */
    background-clip: padding-box;
    background-color: $button-purple;
  }
  .button-wrapper.button-wrapper-bg-2 {
    border-image-source: url("/ui/Assets_Icon_Border_QtrCircle_1_Purple.png");
  }
  .button-wrapper.is-active,
  .button-wrapper:hover {
    filter: brightness(145%);
  }

  .button-wrapper div {
    padding: 0 10px;
  }

  button:disabled {
    color: #999;
  }

  button:focus {
    border-color: #666;
  }
  .list {
    gap: 10px;
    display: flex;
    flex-direction: column;
    max-width: 200px;
  }
  .full-fill {
    width: 100vw;
    height: 100vh;
  }
  .decorative-border {
    width: 100%;
    height: 100%;
    border: 20px solid;
    user-select: none;
    border-image-source: url("/ui/Assets_Gif_Border.png");
    border-image-slice: 30;
    border-image-width: 50px;
    border-image-outset: 0;
    box-sizing: border-box;
    // background-color is necessary for this border on FF but it must not be set on Chrome
    // background-color: #171717;
  }
  .pad-20 {
    padding: 20px;
  }
</style>

<script>
    export let text;
    export let iconPath;
    export let isActive;
    export let doLocalizeText = true;
    let _class = "";
    export { _class as class };
</script>

<button
    class={`button-wrapper ${_class} ${isActive ? "is-active" : ""}`}
    on:click
    {...$$restProps}
>
    <div class="button-inner">
        {#if iconPath}<div
                class="icon"
                style={`background-image: url('ui/icons/${iconPath}')`}
            />{/if}
        <span class="button-text">
            {doLocalizeText ? globalThis.i18n(text) : text}
        </span>
    </div>
</button>

<style>
    .button-inner {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: stretch;
    }
    .icon {
        background-repeat: no-repeat;
        background-size: cover; /* Make the image cover the div */
        background-position: 50%; /* Center the image inside the div */
        margin-right: 8px;
    }
    .button-text {
        width: 100%;
        white-space: nowrap;
    }
</style>
